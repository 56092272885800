import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

const switchData = (data: { en: any; ka: any }, langKey: any) => {
    // eslint-disable-next-line no-unused-vars
    var posts: any
    switch (langKey) {
        case 'en':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

class BlogNav extends React.Component {
    posts: any
    static propTypes: {
        data: PropTypes.Requireable<
            PropTypes.InferProps<{
                allMarkdownRemark: PropTypes.Requireable<
                    PropTypes.InferProps<{
                        edges: PropTypes.Requireable<any[]>
                    }>
                >
            }>
        >
    }
    constructor(props: Readonly<{}>) {
        super(props)
        this.state = { url: '' }
    }

    getUrl() {
        const urlChecker = window.location.pathname.includes('/ka/blog/')
            ? window.location.pathname
            : '/en/blog/'
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    toObject() {
        const dateForm = this.posts.map(
            ({ node: post }) => post.frontmatter.date
        )
        var rv = {}
        for (var i = 0; i < dateForm.length; ++i) rv[i] = dateForm[i]
        return rv
    }

    render() {
        var data: any
        if (this.props.data !== null) {
            data = this.props.data
        }
        const langKey = this.state.url.slice(1, 3)
        const langFixer = langKey === 'en' ? '' : 'ka'
        const posts = switchData(data, langKey)
        const post = posts.group
        return (
            <div className="tabs">
                <ul>
                    {post &&
                        post.map((tag: { fieldValue: {} }) => (
                            <Link
                                to={`${langFixer}/blog-tags/${kebabCase(
                                    tag.fieldValue
                                )}/`}
                                className="has-text-weight-medium is-blog-navbar-color"
                                activeClassName="is-active-tag"
                            >
                                {tag.fieldValue}
                            </Link>
                        ))}
                </ul>
            </div>
        )
    }
}

BlogNav.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query BlogNavQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(en|any)/" }
                        }
                    }
                ) {
                    group(field: frontmatter___tags) {
                        fieldValue
                    }
                }
                ka: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(ka|any)/" }
                        }
                    }
                ) {
                    group(field: frontmatter___tags) {
                        fieldValue
                    }
                }
            }
        `}
        render={(data) => <BlogNav key={data.id} data={data} />}
    />
)
